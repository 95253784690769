/** namespace для Промо леера */
var OK = window.OK || (window.OK = {});

OK.PromoApp = (function (window, document) {
    // Кнопочка поиска в тулбаре
    var liveSearchBtnId = "#hook_FormButton___lsBtn";
    var promoOverlay, promoCloseBtn, liveSearchBtn;
    
    var MEMORIES_PARENT_CLASS = '.__promo-modal-memories';
    var MEMORIES_TEST_PARENT_CLASS = '.__promo-modal-memories_test';
    var PROMO_LAYER_CONTAINER_SELECTOR = '#hook_Block_PromoMainLayer';
    var OVERLAY_SELECTOR = '#popLayer_mo';
    var CLOSE_ICON_SELECTOR = ".media-layer_close"
    
    function onCloseApp(e) {
        e.stopPropagation();

        if (window.OKAPI) {
            window.OKAPI.postCallback("closeApp", "ok");
        }
    }

    /** закрытие леера */
    function closePromoApp() {
        OK.hookModel.setHookContent('PromoMainLayer', ''); // binded from GWT
    }

    /** метод на закрытие леера */
    function deactivate() {
        // Реализовать закрытие всех поп-лееров по нажатию ESC и клику в серую область (OL-36760)
        OK.Layers.remove("layer_promo");

        if (liveSearchBtn) {
            liveSearchBtn.removeEventListener('click', closePromoApp);
        }
        
        if (promoOverlay) {
            promoOverlay.removeEventListener('click', onCloseApp);
        }

        if (promoCloseBtn) {
            promoCloseBtn.removeEventListener('click', onCloseApp);
        }
    }

    /**
     * Инициализируем промо-леер
     * Добавляем обработчика на кнопу ESC
     */
    function activate() {
        // Скрывает тултип подарка на аватарке при открытии промо-леера
        var presentSender = document.querySelector('.presentSender');
        if (presentSender) {
            presentSender.style.display = 'none';
        }

        // Реализовать закрытие всех поп-лееров по нажатию ESC и клику в серую область (OL-36760)
        OK.Layers.register("layer_promo", closePromoApp);

        liveSearchBtn = document.querySelector(liveSearchBtnId);
        if (liveSearchBtn) {
            liveSearchBtn.addEventListener('click', closePromoApp);
        }

        // Получем аудио элемент (есть не во всех промо леерах)
        var audio = document.querySelector('#promoLayer_audio');
        if (audio) {
            var audioBut = document.querySelector('#promoLayer_audio_but');
            if (audioBut) {
                audioBut.addEventListener('click', function () {
                    audioBut.classList.toggle('__off', !audio.paused);
                    if (audio.paused) {
                        audio.play();
                    } else {
                        audio.pause();
                    }
                });
            }

            // Паузим портальную музыку
            require(['OK/music2/app'], function(app) {
                app.pause();
            })
        }

        var promoLayerContainer = document.querySelector(PROMO_LAYER_CONTAINER_SELECTOR);
        if (!promoLayerContainer) {
            return;
        }

        var parent = promoLayerContainer.querySelector(MEMORIES_PARENT_CLASS);
        parent = parent ? parent : promoLayerContainer.querySelector(MEMORIES_TEST_PARENT_CLASS);

        var memoriesV2App = parent && parent.classList.contains('__v2');
        if (!memoriesV2App) {
            return;
        }

        promoOverlay = parent.querySelector(OVERLAY_SELECTOR);
        if (promoOverlay) {
            promoOverlay.addEventListener('click', onCloseApp);
        }

        promoCloseBtn = parent.querySelector(CLOSE_ICON_SELECTOR);
        if (promoCloseBtn) {
            promoCloseBtn.addEventListener('click', onCloseApp);
        }
    }

    return {
        activate: activate,
        deactivate: deactivate
    };
})(window, document);
